import moment from 'moment';
import {
  DATE_FORMAT_ISO,
  DATETIME_FORMAT_ISO_MILISECONDS,
  DATETIME_FORMAT_ISO,
  DATE_FORMAT_WEB,
} from '../Constants/DateFormatConstants';

// with this method the component react-datetime
// not fail every time that i want format the date
export const DateTimeFormater = (stringValue, formatIn, formatOut) => {
  //console.log('DateTimeFormater', stringValue, formatIn, formatOut);
  if (!stringValue) return null;

  var dateFormats = [];
  if (formatIn != null) {
    dateFormats.push(formatIn);
  } else {
    dateFormats.push(DATE_FORMAT_ISO);
    dateFormats.push(DATETIME_FORMAT_ISO);
    dateFormats.push(DATETIME_FORMAT_ISO_MILISECONDS);
    dateFormats.push(DATE_FORMAT_WEB);
  }

  let date = moment(stringValue, dateFormats);
  if (date === 'Invalid date') return null;
  //console.log('date out', date.format(formatOut));
  return date.format(formatOut);
};
