import axios from 'axios';
import download from 'downloadjs';
import { toastr } from 'react-redux-toastr';

import { adalApiFetch } from 'Configuration/AdalConfiguration';
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

const baseUrl = `${getEnvironmentVariablesHandler().apiBaseUrl}`;
const baseGpxUrl = `${getEnvironmentVariablesHandler().apiGpxBaseUrl}`;

class RestClient {
  // Helper function to handle API requests
  static async handleRequest(url, settings, base = baseUrl) {
    var axiosInstance = axios.create({
      baseURL: base,
    });
    try {
      let response = await adalApiFetch(axiosInstance, base + url, settings);
      return response?.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occurred: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Get(url) {
    return this.handleRequest(url, {});
  }

  static async GetFromGpx(url) {
    return this.handleRequest(url, {}, baseGpxUrl);
  }

  static async Post(url, data) {
    let settings = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: '',
      },
      data: JSON.stringify(data),
      method: 'POST',
    };
    return this.handleRequest(url, settings);
  }

  static async Delete(url, data) {
    let settings = {
      headers: {
        Accept: 'Application/json',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: '',
      },
      data: JSON.stringify(data),
      method: 'DELETE',
    };
    return this.handleRequest(url, settings);
  }

  static async Put(url, data) {
    let settings = {
      headers: {
        Accept: 'Application/json',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: '',
      },
      data: JSON.stringify(data),
      method: 'PUT',
    };
    return this.handleRequest(url, settings);
  }

  static async Download(url, data, fileName) {
    let settings = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: '',
      },
      data: JSON.stringify(data),
      method: 'POST',
      responseType: 'arraybuffer',
    };

    try {
      let response = await this.handleRequest(url, settings);

      if (response.byteLength === 0) {
        return {
          message: 'It seems you do not have permission to perform this action, please contact your manager.',
        };
      }

      // Create a Blob with the correct MIME type for a .xlsb file
      const blob = new Blob([response], { type: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12' });

      // Use a download function that can handle Blob objects
      return download(blob, fileName);
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occurred: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Upload(url, file, data) {
    try {
      // File
      var formData = new FormData();
      formData.append('file', file);

      /// Data
      if (Array.isArray(data)) {
        for (var item of data) {
          formData.append(item.key, item.value);
        }
      } else {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                if (typeof item === 'object' && !(item instanceof File)) {
                  for (const subKey in item) {
                    if (Object.prototype.hasOwnProperty.call(item, subKey)) {
                      formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
                    }
                  }
                } else {
                  formData.append(`${key}[]`, item);
                }
              });
            } else if (typeof value !== 'object' || value instanceof File) {
              formData.append(key, value);
            }
          }
        }
      }

      var axiosInstance = axios.create({
        baseURL: baseUrl,
      });

      const options = {
        method: 'POST',
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, options);
      return response.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occurred: ${err.response?.data?.message ?? err}.`);
    }
  }
}

export default RestClient;
