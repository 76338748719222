import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';

import { DATETIME_FORMAT_ISO, DATE_FORMAT_ISO, DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import airports from 'Constants/airports.json';
import RestClient from 'Services/RestClientService';
import { DS_F2W_Edit, DS_F2W_HR_Team, DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';
import NTAN from './NTAN';

const BusinessSupportCard = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent, setFlyToWorkTicketInfo } = props;
  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const parseBooleanOrNull = (value) => {
    if (value === 'true' || value === true) return true;
    if (value === 'false' || value === false) return false;
    return null;
  };

  // Map airports for select options
  const mappedAirports = airports.map((airport) => ({
    value: airport.iata_code,
    label: `${airport.iata_code} - ${airport.name}`,
  }));

  // Handle flight ticket info update
  const handleUpdateFlightTicketInfo = (fieldName, fieldValue) => {
    const payload = { [fieldName]: fieldValue };
    RestClient.Post(`flight-ticket/update/${flyToWorkTicketInfo.id}`, payload).then(() => {
      setRefreshComponent(Math.random());
    });
  };

  // Determine if Preferred Flight Date field is disabled
  const isPreferredFlightDateDisabled = () => {
    if (
      userRoles?.includes(DS_F2W_HR_Team) &&
      flyToWorkTicketInfo.noFlightNeeded &&
      flyToWorkTicketInfo.status?.toLowerCase() === 'pendinghr'
    )
      return false;

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      return false;

    return true;
  };

  // Determine if Arrival/Departure Airport fields are disabled
  const isArrivalDepartureAirportDisabled = () => {
    if (flyToWorkTicketInfo.noFlightNeeded) return true;

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      return false;

    return true;
  };

  // Determine if OS fields are disabled
  const isOSFieldDisabled = () => {
    if (
      userRoles?.includes(DS_F2W_Business_Travel_Team) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'pendingbtt')
    )
      return false;

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      return false;

    return true;
  };

  // Determine if OS fields are disabled when no travel needed
  const isOSFieldDisabledNoTravelNeeded = () => {
    if (
      userRoles?.includes(DS_F2W_Edit) ||
      (userRoles?.includes(DS_F2W_Business_Travel_Team) &&
        (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
          flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed' ||
          flyToWorkTicketInfo.status?.toLowerCase() === 'new'))
    )
      return false;

    return true;
  };

  // Determine if GreenLight checkbox is disabled
  const isGreenLightCheckBoxDisabled = () => {
    if (userRoles?.includes(DS_F2W_HR_Team) && flyToWorkTicketInfo?.status?.toLowerCase() === 'pendinghr') return false;

    return true;
  };

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-check mr-2"></i> Business support information
      </Card.Header>
      <Card.Body>
        {/* Personal Information */}
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.firstName} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.lastName} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Second Last Name</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.lastName2} readOnly />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Date of birth</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
              value={DateTimeFormater(flyToWorkTicketInfo.dateOfBirth, null, DATE_FORMAT_WEB)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.phone} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Gender</Form.Label>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Male"
                  name="gender_radio_button"
                  type="radio"
                  id="male_radio_button"
                  value="Male"
                  checked={flyToWorkTicketInfo.gender?.toLowerCase() === 'male'}
                  disabled
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender_radio_button"
                  type="radio"
                  id="female_radio_button"
                  value="Female"
                  checked={flyToWorkTicketInfo.gender?.toLowerCase() === 'female'}
                  disabled
                />
                <Form.Check
                  inline
                  label="Unknow"
                  name="gender_radio_button"
                  type="radio"
                  id="unknow_radio_button"
                  value="Unknow"
                  checked={!flyToWorkTicketInfo.gender}
                  disabled
                />
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        <hr />
        {/* Staff Information */}
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Staff Source Market</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.staffSourceMarket} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Source Market Budget</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.staffSourceMarketBudget} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Season</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.season} readOnly />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.country} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Destination</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.destination} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Position Job title</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.jobTitle} readOnly />
          </Form.Group>
        </Form.Row>
        <hr />
        {/* Flight Information */}
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              Planned Assignment {flyToWorkTicketInfo?.direction?.toLowerCase() === 'arriving' ? 'Start' : 'End'} Date
            </Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
              value={DateTimeFormater(flyToWorkTicketInfo.plannedAssignmentStartDate, null, DATE_FORMAT_WEB)}
              onChange={(e) => handleUpdateFlightTicketInfo('plannedAssignmentStartDate', e.format(DATE_FORMAT_ISO))}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Preferred Flight Date</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: isPreferredFlightDateDisabled() }}
              value={DateTimeFormater(flyToWorkTicketInfo.preferredFlightDate, null, DATE_FORMAT_WEB)}
              onChange={(e) => {
                if (!e) return null;
                handleUpdateFlightTicketInfo('preferredFlightDate', e.format(DATE_FORMAT_ISO));
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Type of flight</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.typeOfFlight} readOnly />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Additional email to notification</Form.Label>
            <CreatableSelect
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                if (!e) return null;
                const emailRegex = /^[^\s@]+@(?:tui\.com|tui\.de)$/i;
                if (!e.every((email) => emailRegex.test(email.value))) {
                  const popEmail = e.pop();
                  toastr.warning('Error', `${popEmail.value} is invalid email, please review.`);
                  handleUpdateFlightTicketInfo('additionNotificationEmails', []);
                  return null;
                }
                handleUpdateFlightTicketInfo('additionNotificationEmails', e);
              }}
              value={flyToWorkTicketInfo.additionNotificationEmails}
              isDisabled={isOSFieldDisabled()}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Departure Airports</Form.Label>
            <Select
              options={mappedAirports}
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                handleUpdateFlightTicketInfo(
                  'departureAirports',
                  e.map((x) => x.value)
                );
              }}
              value={mappedAirports
                .filter((x) => flyToWorkTicketInfo.departureAirports?.includes(x.value) || null)
                .sort(
                  (a, b) =>
                    flyToWorkTicketInfo.departureAirports.indexOf(a.value) -
                    flyToWorkTicketInfo.departureAirports.indexOf(b.value)
                )}
              isDisabled={isArrivalDepartureAirportDisabled()}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Arrival Airports</Form.Label>
            <Select
              options={mappedAirports}
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                handleUpdateFlightTicketInfo(
                  'arrivalAirports',
                  e.map((x) => x.value)
                );
              }}
              value={mappedAirports
                .filter((x) => flyToWorkTicketInfo.arrivalAirports?.includes(x.value) || null)
                .sort(
                  (a, b) =>
                    flyToWorkTicketInfo.arrivalAirports.indexOf(a.value) -
                    flyToWorkTicketInfo.arrivalAirports.indexOf(b.value)
                )}
              isDisabled={isArrivalDepartureAirportDisabled()}
            />
          </Form.Group>
        </Form.Row>
        <hr />
        {/* Additional Options */}
        <Form.Row>
          <Form.Group as={Col}>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Hotel Needed"
                  name="is_hotel_needed_checkbox"
                  type="checkbox"
                  id="hotelNeeded_checkbox"
                  value="isHotelNeeded"
                  checked={parseBooleanOrNull(flyToWorkTicketInfo.isHotelNeeded)}
                  onChange={(e) => handleUpdateFlightTicketInfo('isHotelNeeded', e.target.checked)}
                  disabled={isOSFieldDisabled()}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="Book Return Flight"
                  name="is_book_return_flight_checkbox"
                  type="checkbox"
                  id="isBookReturnFlight_checkbox"
                  value="isBookReturnFlight"
                  checked={parseBooleanOrNull(flyToWorkTicketInfo.isBookReturnFlight)}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('isBookReturnFlight', e.target.checked);
                    if (!e.target.checked) {
                      handleUpdateFlightTicketInfo('brfReturnFlightDate', null);
                      handleUpdateFlightTicketInfo('brfDepartureAirport', null);
                      handleUpdateFlightTicketInfo('brfArrivalAirport', null);
                    }
                  }}
                  disabled={isOSFieldDisabled()}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="Train ticket"
                  name="is_rail_and_fly_checkbox"
                  type="checkbox"
                  id="isRailAndFly_checkbox"
                  value="isRailAndFly"
                  checked={parseBooleanOrNull(flyToWorkTicketInfo.isRailAndFly)}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('isRailAndFly', e.target.checked);
                    if (!e.target.checked) {
                      handleUpdateFlightTicketInfo('homeTrainStationName', null);
                    }
                  }}
                  disabled={isOSFieldDisabled()}
                />
              </Col>
              <Col>
                <NTAN
                  flyToWorkTicketInfo={flyToWorkTicketInfo}
                  setFlyToWorkTicketInfo={setFlyToWorkTicketInfo}
                  isOSFieldDisabledNoTravelNeeded={isOSFieldDisabledNoTravelNeeded}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '1%' }}>
              <Col>
                <Form.Check
                  inline
                  label="GreenLight"
                  name="isGreenLighted"
                  type="checkbox"
                  id="isGreenLight_checkbox"
                  value="isGreenLighted"
                  checked={parseBooleanOrNull(flyToWorkTicketInfo.isGreenLighted)}
                  onChange={(e) => handleUpdateFlightTicketInfo('isGreenLighted', e.target.checked)}
                  disabled={isGreenLightCheckBoxDisabled()}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        {/* Book Return Flight Section */}
        <div hidden={!parseBooleanOrNull(flyToWorkTicketInfo.isBookReturnFlight)}>
          <hr />
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Return Flight Date</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB }}
                value={DateTimeFormater(flyToWorkTicketInfo.brfReturnFlightDate, null, DATE_FORMAT_WEB)}
                onChange={(e) =>
                  handleUpdateFlightTicketInfo('brfReturnFlightDate', DateTimeFormater(e, null, DATETIME_FORMAT_ISO))
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Departure Airport</Form.Label>
              <Select
                options={mappedAirports}
                isSearchable
                isClearable
                isMulti
                onChange={(e) => {
                  handleUpdateFlightTicketInfo(
                    'departureAirports',
                    e.map((x) => x.value)
                  );
                }}
                value={mappedAirports
                  .filter(
                    (x) =>
                      Array.isArray(flyToWorkTicketInfo.brfDepartureAirport) &&
                      flyToWorkTicketInfo.brfDepartureAirport.includes(x.value)
                  )
                  .sort((a, b) =>
                    Array.isArray(flyToWorkTicketInfo.brfDepartureAirport)
                      ? flyToWorkTicketInfo.brfDepartureAirport.indexOf(a.value) -
                        flyToWorkTicketInfo.brfDepartureAirport.indexOf(b.value)
                      : 0
                  )}
                isDisabled={isArrivalDepartureAirportDisabled()}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Arrival Airport</Form.Label>
              <Select
                options={mappedAirports}
                isSearchable
                isClearable
                isMulti
                onChange={(e) => {
                  handleUpdateFlightTicketInfo(
                    'departureAirports',
                    e.map((x) => x.value)
                  );
                }}
                value={mappedAirports
                  .filter(
                    (x) =>
                      Array.isArray(flyToWorkTicketInfo.brfArrivalAirport) &&
                      flyToWorkTicketInfo.brfArrivalAirport.includes(x.value)
                  )
                  .sort((a, b) =>
                    Array.isArray(flyToWorkTicketInfo.brfArrivalAirport)
                      ? flyToWorkTicketInfo.brfArrivalAirport.indexOf(a.value) -
                        flyToWorkTicketInfo.brfArrivalAirport.indexOf(b.value)
                      : 0
                  )}
                isDisabled={isArrivalDepartureAirportDisabled()}
              />
            </Form.Group>
          </Form.Row>
        </div>
        {/* Rail and Fly Section */}
        <div hidden={!parseBooleanOrNull(flyToWorkTicketInfo.isRailAndFly)}>
          <hr />
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Train station name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={flyToWorkTicketInfo.homeTrainStationName}
                onBlur={(e) => handleUpdateFlightTicketInfo('homeTrainStationName', e.target.value)}
              />
            </Form.Group>
          </Form.Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BusinessSupportCard;
